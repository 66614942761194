import React, { useRef, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import logo from '../assets/header.logo.png';
import { SidebarContext } from '../context/SidebarContext'
import { ThemeContext } from '../context/ThemeContext'
import {
  MenuIcon,
  MoonIcon,
  SunIcon,
} from '../icons'

const Header = (props) => {
  const { toggleSidebar } = useContext(SidebarContext)
  const { theme, toggleTheme } = useContext(ThemeContext)

  return (
    <header className="z-10 py-4 bg-white shadow-md dark:bg-monokai">
      <div className={`container flex items-center justify-between h-full px-6 mx-auto text-gray-500 dark:text-white flex-row md:flex-row-reverse`}>
        <button className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none" aria-label="Menu" onClick={toggleSidebar}>
          <MenuIcon className="w-5 h-5" />
        </button>

        <ul className="flex items-center flex-shrink-0 space-x-6">
          <li className="flex">
            <button className="rounded-md focus:outline-none" aria-label="Toggle color mode" onClick={toggleTheme}>
            {theme === 'dark' ? (
                <SunIcon className="w-5 h-5" />
              ) : (
                <MoonIcon className="w-5 h-5" />
              )}
            </button>
          </li>
         
          <li className="relative">
            <Link to="/login">
            <button className="rounded-full focus:shadow-outline-purple focus:outline-none" aria-label="Account" aria-haspopup="true">
              <div className="relative rounded-full inline-block w-8 h-8 align-middle" aria-hidden="true">
                <img className="object-cover w-full h-full rounded-lg" src={logo} alt="" loading="lazy" />
                <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
              </div>
            </button>
            </Link>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Header;
