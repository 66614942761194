import React, { useRef, useState, useContext } from 'react'
import { SidebarContext } from '../context/SidebarContext'
import { Link, Route } from 'react-router-dom'
import {
  HomeIcon,
  CalendarIcon,
  SunIcon,
} from '../icons'


const SideNav = () => {
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext)

  return (
    <aside className={`navbar ${isSidebarOpen ? "fixed md:static h-screen" : "hidden"} z-20 w-64 overflow-y-auto bg-white dark:bg-monokai md:block mt-16 md:mt-0 flex-shrink-0 py-5 px-4 transition-transform ease-in duration-200`}>
      <a className="text-lg text-gray-800 dark:text-gray-200" href="#">
        dev blockchain
      </a>

      <ul className="nav flex flex-col overflow-hidden mt-2">
        <li className="nav-item">
          <Link to="/app/dashboard" onClick={toggleSidebar}
            className="flex flex-row items-center h-11 pr-6 border-l-4 border-transparent text-gray-500 focus:outline-none
            hover:bg-gray-50 hover:text-gray-800 hover:border-indigo-500 hover:text-gray-800
            dark:text-white dark:hover:bg-gray-600
            transform hover:translate-x-2 transition-transform ease-in duration-200">
            <span className="inline-flex justify-center items-center ml-2">
              <HomeIcon className="w-5 h-5" />
            </span>
            <span className="ml-2 text-sm tracking-wide truncate">Dashboard</span>
          </Link>
        </li>

        <li className="hidden nav-item">
          <Link to="/app/xen" onClick={toggleSidebar}
            className="flex flex-row items-center h-11 pr-6 border-l-4 border-transparent text-gray-500 focus:outline-none
            hover:bg-gray-50 hover:text-gray-800 hover:border-indigo-500 hover:text-gray-800
            dark:text-white dark:hover:bg-gray-600
            transform hover:translate-x-2 transition-transform ease-in duration-200">
            <span className="inline-flex justify-center items-center ml-2">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>
            </span>
            <span className="ml-2 text-sm tracking-wide truncate">XEN</span>
          </Link>
        </li>

        <li className="hidden nav-item">
          <Link to="/app/hex" onClick={toggleSidebar}
            className="flex flex-row items-center h-11 pr-6 border-l-4 border-transparent text-gray-500 focus:outline-none
            hover:bg-gray-50 hover:text-gray-800 hover:border-indigo-500 hover:text-gray-800
            dark:text-white dark:hover:bg-gray-600
            transform hover:translate-x-2 transition-transform ease-in duration-200">
            <span className="inline-flex justify-center items-center ml-2">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>
            </span>
            <span className="ml-2 text-sm tracking-wide truncate">HEX</span>
          </Link>
        </li>

        <li className="hidden nav-item">
          <Link to="/app/more" onClick={toggleSidebar}
            className="flex flex-row items-center h-11 pr-6 border-l-4 border-transparent text-gray-500 focus:outline-none
            hover:bg-gray-50 hover:text-gray-800 hover:border-indigo-500 hover:text-gray-800
            dark:text-white dark:hover:bg-gray-600
            transform hover:translate-x-2 transition-transform ease-in duration-200">
            <span className="inline-flex justify-center items-center ml-2">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>
            </span>
            <span className="ml-2 text-sm tracking-wide truncate">MORE</span>
          </Link>
        </li>

        <li className="nav-item">
          <a href="#"
            className="flex flex-row items-center h-11 pr-6 border-l-4 border-transparent text-gray-500 focus:outline-none
            hover:bg-gray-50 hover:text-gray-800 hover:border-indigo-500 hover:text-gray-800
            dark:text-white dark:hover:bg-gray-600
            transform hover:translate-x-2 transition-transform ease-in duration-200">
            <span className="inline-flex justify-center items-center ml-2">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path></svg>
            </span>
            <span className="ml-2 text-sm tracking-wide truncate">Notifications</span>
          </a>
        </li>

        <li className="nav-item">
          <div className="flex flex-row items-center h-8">
            <div className="text-sm font-light tracking-wide text-gray-500">Chainalysis<hr /></div>
          </div>
        </li>

        <li>
          <a href="#"
            className="flex flex-row items-center h-11 pr-6 border-l-4 border-transparent text-gray-500 focus:outline-none
            hover:bg-gray-50 hover:text-gray-800 hover:border-indigo-500 hover:text-gray-800
            dark:text-white dark:hover:bg-gray-600
            transform hover:translate-x-2 transition-transform ease-in duration-200">
          <span className="inline-flex justify-center items-center ml-2">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>
          </span>
          <span className="ml-2 text-sm tracking-wide truncate">Bitcoin</span>
          </a>
        </li>

        <li>
          <a href="#" className="flex flex-row items-center h-11 pr-6 border-l-4 border-transparent text-gray-500 focus:outline-none
            hover:bg-gray-50 hover:text-gray-800 hover:border-indigo-500 hover:text-gray-800
            dark:text-white dark:hover:bg-gray-600
            transform hover:translate-x-2 transition-transform ease-in duration-200">
          <span className="inline-flex justify-center items-center ml-2">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
          </span>
          <span className="ml-2 text-sm tracking-wide truncate">Ethereum</span>
          <span className="px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-green-500 bg-green-50 rounded-full">15</span>
          </a>
        </li>

        <li className="hidden">
          <a href="#"
            className="flex flex-row items-center h-11 pr-6 border-l-4 border-transparent text-gray-500 focus:outline-none
            hover:bg-gray-50 hover:text-gray-800 hover:border-indigo-500 hover:text-gray-800
            dark:text-white dark:hover:bg-gray-600
            transform hover:translate-x-2 transition-transform ease-in duration-200">
          <span className="inline-flex justify-center items-center ml-2">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>
          </span>
          <span className="ml-2 text-sm tracking-wide truncate">Cronos</span>
          </a>
        </li>

      </ul>
    </aside>
  )
};

export default SideNav;
