import React, { useContext, Suspense, useEffect, lazy } from 'react'
import {BrowserRouter as Router, Navigate, Route, Link, Routes} from 'react-router-dom'

import Header from '../components/header'
import SideNav from '../components/sidenav'
import Main from './main'

const Dashboard = lazy(() => import('../pages/Dashboard'))
const Events = lazy(() => import('../pages/Events'))
// const Hex = lazy(() => import('../pages/Hex'))
// const More = lazy(() => import('../pages/More'))
const Xen = lazy(() => import('../pages/Xen'))

const Page404 = lazy(() => import('../pages/404'))

const Layout = () => {
  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <SideNav />

      <div className="flex flex-col flex-1 w-full">
        <Header />
        <Main>
          <Suspense>
            <Routes>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="xen" element={<Xen />} />
              {/* 
              <Route path="hex" element={<Hex />} />
              <Route path="more" element={<More />} />
              <Route path="events" element={<Events />} />
              */}
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Suspense>
        </Main>
      </div>

    </div>
  )
}

export default Layout