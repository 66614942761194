import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client'

import './assets/main.css';
import App from './App';
import { SidebarProvider } from './context/SidebarContext'
import { ThemeProvider } from './context/ThemeContext';

const root = createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <ThemeProvider>
      <SidebarProvider>
        <App />
      </SidebarProvider>
    </ThemeProvider>
  </StrictMode>
)
