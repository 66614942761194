import React, { Suspense, lazy } from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Login from './pages/Login'
import Layout from './containers/Layout'
const Page404 = lazy(() => import('./pages/404'))

function App() {
  return (
    <>
      <Suspense>
      <Router>
        <Routes>
          <Route path="/app/*" element={<Layout />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
      </Suspense>
    </>
  );
}

export default App;
