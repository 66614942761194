import React, { useRef, useState, useContext } from 'react'
import { ThemeContext } from '../context/ThemeContext'
import './main.css'

const Main = ({ children }) => {
  const { theme } = useContext(ThemeContext)

  return (
    <main className={`${theme === 'dark' ? 'dark' : 'light'} h-full px-4 md:px-10 md:py-4 overflow-y-auto bg-background dark:bg-inherit`}>
      <div className="container px-2 md:px-6 mx-auto grid">{children}</div>
    </main>
  )
}

export default Main