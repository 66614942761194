
import React from 'react'
import { Link } from 'react-router-dom'

function Login() {
  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <p className="mt-4">
        <Link to="/"
          className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline">
          Back Home
        </Link>
      </p>
    </div>
  )
}

export default Login